/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./layout.module.scss"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({
  children,
  noHeader,
  pageTitle,
  noFooter,
  noSubFooter,
  dark,
  headlineBlue,
  solutionName,
  dataSheetLink,
}) => {
  const data = useStaticQuery(graphql`
    query TitleQuery {
      header: allAirtable(
        filter: { table: { eq: "Header" } }
        sort: { order: DESC }
      ) {
        nodes {
          data {
            Site_Title
          }
        }
      }
    }
  `)
  const {
    header: { nodes },
  } = data
  return (
    <>
      <Helmet>
        <body className={`${dark ? "bg-dark text-light" : "bg-primary"}`} />
      </Helmet>
      {!noHeader && (
        <Header
          siteTitle={nodes[0].data.Site_Title}
          pageTitle={pageTitle}
          dark={dark}
          headlineBlue={headlineBlue}
        />
      )}
      <div className={styles.wrapper}>
        <main className={`${!noFooter && !noSubFooter && styles.correctMain}`}>
          {children}
        </main>
        {!noFooter && (
          <Footer
            noSubFooter={noSubFooter}
            solutionName={solutionName}
            dataSheetLink={dataSheetLink}
          ></Footer>
        )}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noHeader: PropTypes.bool,
  pageTitle: PropTypes.string,
  noFooter: PropTypes.bool,
  noSubFooter: PropTypes.bool,
  dark: PropTypes.bool,
  headlineBlue: PropTypes.bool,
  solutionName: PropTypes.string,
  dataSheetLin: PropTypes.string,
}

export default Layout
