import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { Nav, Container, Row, Col } from "react-bootstrap"
import styles from "./header.module.scss"

const Header = ({ siteTitle, pageTitle, dark, headlineBlue }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      header: allAirtable(
        filter: { table: { eq: "Header" } }
        sort: { fields: recordId, order: DESC }
      ) {
        nodes {
          data {
            Subhead
            Logo_Light {
              localFiles {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            Logo_Dark {
              localFiles {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
          recordId
        }
      }
    }
  `)

  const {
    header: { nodes },
  } = data
  return (
    <header className={`${styles.header}`}>
      <Nav
        className={`d-flex justify-content-between align-items-center ${styles.nav}`}
      >
        <Nav.Item>
          <Nav.Link href="/" className={`p-0`}>
            <Img
              fluid={
                dark
                  ? nodes[0].data.Logo_Light.localFiles[0].childImageSharp.fluid
                  : nodes[0].data.Logo_Dark.localFiles[0].childImageSharp.fluid
              }
              className={`${styles.logo}`}
            />
          </Nav.Link>
        </Nav.Item>
        <h3 className={`font-weight-light text-white ${styles.subhead}`}>
          {nodes[0].data.Subhead}
        </h3>
      </Nav>
      {pageTitle && (
        <Container fluid className={`h-100 p-0`}>
          <Row className={`p-0 m-0`}>
            <Col className={`p-0 w-100 text-center`}>
              <h1
                className={`pt-5 mb-0 font-weight-light ${headlineBlue &&
                  "text-white"} ${styles.pageHeadline}`}
              >
                {pageTitle}
              </h1>
            </Col>
          </Row>
        </Container>
      )}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  pageTitle: PropTypes.string,
  dark: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  pageTitle: ``,
  dark: false,
}

export default Header
