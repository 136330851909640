import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React, {useState} from "react"
import { navigate } from "@reach/router"
import { Nav, Container, Row, Col, Form, Button } from "react-bootstrap"
import styles from "./footer.module.scss"
import { slugify } from "../utils/slugify.js"

const Footer = ({ solutionName, noSubFooter, dataSheetLink }) => {
  const data = useStaticQuery(graphql`
    query FooterAndSolutionsQuery {
      footer: allAirtable(
        filter: { table: { eq: "Footer" } }
        sort: { order: DESC }
      ) {
        nodes {
          data {
            Email_Head
            Email_Description
            Field_Placeholder
            Submit_Text
            Privacy_Notice_Head
            Privacy_Notice_Description
            Privacy_Notice_Link_Text
            Privacy_Policy
            Home_Icon {
              localFiles {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 512) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
      solutions: allAirtable(
        filter: { table: { eq: "Solutions" } }
        sort: { fields: data___Sort, order: ASC }
      ) {
        nodes {
          data {
            Name
          }
          recordId
        }
      }
    }
  `)

  const {
    footer: { nodes: footerNodes },
    solutions: { nodes: solutionsNodes },
  } = data

  const handleChange = e => {
    const value = e.target.value
    if (dataSheetLink) {
      if (value === "letsgo") {
        navigate(dataSheetLink)
      }
    }
  }

  const [privacyNotice, setPrivacyNotice] = useState(false)

  return (
    <footer className={`${styles.footer}`}>
      <Container fluid className={`p-0`}>
        {!noSubFooter && (
          <Row
            className={`d-flex justify-content-between align-items-center bg-gray ${styles.footerContainer} ${styles.footerContainerTop}`}
          >
            <Col className={`p-0 w-100 text-right ${styles.subFooterCol}`}>
              <h2
                className={`m-0 d-flex row p-0 mb-0 font-weight-bold ${styles.subFooterEmailHead} ${privacyNotice && styles.subFooterNotice}`}
              >
                {privacyNotice ? footerNodes[0].data.Privacy_Notice_Head : footerNodes[0].data.Email_Head}
                {<a
                href="https://bpcs.com/privacy-policy/"
                className={`mt-2 ${styles.privacyLink}`}
              >
              {footerNodes[0].data.Privacy_Notice_Link_Text}
              </a>}
              </h2>
            </Col>
            <Col className={`p-0 w-100 text-left ${styles.subFooterCol}`}>
              <h3
                className={`p-0 mb-0 font-weight-light text-left ${styles.subFooterDescription} ${privacyNotice && styles.subFooterNotice}`}
              >
                {privacyNotice ? footerNodes[0].data.Privacy_Notice_Description : footerNodes[0].data.Email_Description}
              </h3>
            </Col>
            <Col className={`p-0 w-100 text-left ${styles.subFooterCol}`}>
              <Form
                method="post"
                action={`/${slugify(solutionName)}/success/`}
                className={`d-flex justify-content-start m-0`}
                name={`${solutionName}`}
                netlify-honeypot="bot-field"
                data-netlify="true"
              >
                <Form.Group
                  controlId="formBasicEmail"
                  className={`mb-0 mr-2 ${styles.subFooterEmailField}`}
                >
                  <Form.Control
                    type="hidden"
                    name="form-name"
                    value={`${solutionName}`}
                  />
                  <Form.Control
                    required
                    type="email"
                    name="Email"
                    placeholder={footerNodes[0].data.Field_Placeholder}
                    aria-label="Email Address"
                    onChange={e => handleChange(e)}
                    onFocus={() => setPrivacyNotice(true)}
                    onBlur={() => setPrivacyNotice(false)}
                  />
                  <Form.Control
                    type="hidden"
                    name={`Solution`}
                    value={`${solutionName}`}
                  />
                  <Form.Control type="hidden" name="bot-field" />
                  {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
                </Form.Group>
                <Button variant="success" type="submit" className={`pl-4 pr-4`}>
                  {footerNodes[0].data.Submit_Text}
                </Button>
              </Form>
            </Col>
          </Row>
        )}
        <Row
          className={`d-flex justify-content-between align-items-center bg-black ${styles.footerContainer} ${styles.footerContainerBottom}`}
        >
          <Nav
            className={`d-flex justify-content-between align-items-center w-100 ${styles.nav}`}
          >
            <Nav.Item className={`flex-grow-0 ${styles.navItem}`}>
              <Nav.Link href="/solutions" className={`p-0`}>
                <Img
                  fluid={
                    footerNodes[0].data.Home_Icon.localFiles[0].childImageSharp
                      .fluid
                  }
                  className={`${styles.homeIcon}`}
                />
              </Nav.Link>
            </Nav.Item>
            {solutionsNodes.map((node, i) => {
              return (
                <Link
                  to={`/${slugify(node.data.Name)}/`}
                  activeClassName={`${styles.activefooterLink}`}
                  className={`${styles.footerLink}`}
                  key={i}
                >
                  <Nav.Item key={i} className={`flex-grow-1`}>
                    {node.data.Name}
                  </Nav.Item>
                </Link>
              )
            })}
          </Nav>
        </Row>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  solutionName: PropTypes.string,
  noSubFooter: PropTypes.bool,
  dataSheetLink: PropTypes.string,
}

Footer.defaultProps = {
  solutionName: "",
  noSubFooter: false,
  dataSheetLink: "",
}

export default Footer
