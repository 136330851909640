const slugify = function(title) {
  return (
    title
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/-a-/g, "-") // Replace '-a-' with '-'
      .replace(/(and|the)/g, "") // Replace 'and' and 'the' with ''
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, "") // Remove all non-word characters
      // eslint-disable-next-line
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, "")
  ) // Trim - from end of text
}
module.exports.slugify = slugify
